import React, { useState, useEffect } from 'react'
import { Box, Grid, Avatar, Typography, IconButton, Button, CircularProgress } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import styles from './index.module.scss'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import ImageCarousel from './ImageCarousel'
import CarouselIndicator from './CarouselIndicator'

import LikeIcon from 'src/assets/icons/thumbs-up.png'
import ActiveLikeIcon from 'src/assets/icons/thumbs-up-active.png'

import FavIcon from 'src/assets/icons/favorite.png'
import ActiveFavIcon from 'src/assets/icons//favorite-active.png'

import ThinkingIcon from 'src/assets/icons/thinking.png'
import ExpandView from './ExpandView'
import UserAvatar from './UserAvatar'
import { useSaveFeedMutation, useTogglePostLikeMutation } from 'src/api/feed'
import { useNavigate } from 'react-router-dom'
import { routeUrls, userRoles } from 'src/utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'
import { initiateSingleChat } from 'src/store/features/chat/chatSlice'
import { useCreateChannelMutation } from 'src/api/chat'
import ExpandablePostDescription from './ExpandablePostDescription'
import LongDescriptionView from './LongDescriptionView';
import { VideoResponse } from 'src/api/feed/types'
dayjs.extend(relativeTime)

export type UpdatedFeed = {
  id: number
  isReacted: boolean
  isSaved: boolean
  likeCount: number
}

type FeedProps = {
  id: number;
  author: {
    id: number;
    name: string;
    profilePic: string | null;
  };
  title: string;
  description: string;
  isPrivateForAllConnections: boolean;
  isPrivateForSelectedConnections: boolean;
  images: {
    id: number;
    image: string;
    annotations?: string;
    order_number:number
  }[];
  videos:VideoResponse[];
  likeCount: number;
  createdAt: string;
  isReacted: boolean;
  isSaved: boolean;
  tags: {
    id: number;
    name: string;
  }[];
  onUpdateFeed: (feed: UpdatedFeed) => void;
  isPhotoBlur: boolean;
  isChatVisible: boolean;
  headerMenu?: JSX.Element;
  sections: Array<{ id: number; title: string; description: string }>;
  isLongDescription: boolean;
};
const Feed = ({
  id,
  author,
  images,
  videos,
  likeCount,
  createdAt,
  isReacted,
  isSaved,
  title,
  description,
  tags,
  onUpdateFeed,
  isPhotoBlur,
  isChatVisible,
  sections,
  isLongDescription,
  headerMenu = <></>,
}: FeedProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [layoutToggle, setLayoutToggle] = useState(false);
  const [toggleLike, { isLoading: isLikeLoading }] = useTogglePostLikeMutation();
  const [saveFeed, { isLoading: isSaveFeedLoading }] = useSaveFeedMutation();
  const { role, userInfo } = useSelector((state: RootState) => state.auth);

  const [createChannel, { isLoading: isCreateChannelLoading }] = useCreateChannelMutation();

  const [postMedia, setPostMedia] = useState<{id:number, image: string; annotations?: string, type?:'video' | 'image'}[]>([]);


  useEffect(()=>{

  

    const videoType = videos.map((video)=>{
      // eslint-disable-next-line camelcase
      return {type:'video', image: video.url, id: video.id, order_number: video.order_number} as const
    })

    const imageType = images.map((image)=>{
      // eslint-disable-next-line camelcase
      return { ...image,  type:'image', order_number:image.order_number} as const
    })

    const postMedia = [...videoType, ...imageType]
    const sortedPostMedia = postMedia.sort((a, b) => a.order_number - b.order_number)
    setPostMedia(sortedPostMedia)

console.log(id, images, videos, postMedia)
  },[images, videos])

  const togglePostViewLayout = () => {
    setLayoutToggle(prevState => !prevState);
  };

  const navigateToDetailScreen = () => {
    if (role === userRoles.nonHealthCare) {
      return navigate(routeUrls.nonHealthCareUser.postDetail.replace(':postId', id.toString()));
    }
    navigate(routeUrls.postDetail.replace(':postId', id.toString()));
  };

  const showAllComments = () => {
    setLayoutToggle(true);
  };

  const CollapseView = () => {
    return (
      <>
        {isLongDescription ? (
          <LongDescriptionView content={sections} />
        ) : (
          <ExpandablePostDescription text={description} maxLength={200} />
        )}
        
        <Typography variant="subtitle2" mt={1}>
          {' '}
          <a role="button" onClick={navigateToDetailScreen}>
            View Comments
          </a>{' '}
        </Typography>
      </>
    );
  };

  const handleLikeToggle = () => {
    toggleLike(id)
      .unwrap()
      .then(res => {
        console.log(res);

        onUpdateFeed({
          id: id,
          isReacted: !isReacted, // if already liked, then user is performing unlike action
          isSaved: isSaved,
          likeCount: (() => {
            if (isReacted) {
              // if already liked, then user is performing unlike action
              return likeCount - 1;
            } else {
              return likeCount + 1;
            }
          })(),
        });
      })
      .catch(err => {
        console.log('err', err);
      });
  };

  const handleSaveFeed = () => {
    saveFeed(id)
      .unwrap()
      .then(res => {
        console.log(res);

        onUpdateFeed({
          id: id,
          isReacted: isReacted,
          isSaved: !isSaved,
          likeCount: likeCount,
        });
      })
      .catch(err => {
        console.log('err', err);
      });
  };

  const redirectToViewProfile = () => {
    if (role === userRoles.healthCare) {
      navigate(routeUrls.viewProfile.replace(':userId', author.id.toString()));
    }
    if (role === userRoles.nonHealthCare) {
      navigate(routeUrls.nonHealthCareUser.viewOthersProfile.replace(':userId', author.id.toString()));
    }
  };

  const handleStartChat = () => {
    const userId = author.id;
    if (userInfo?.id && userId) {
      createChannel([userInfo.id, userId]).then((res: any) => {
        if (res?.data?.data) {
          const fromUser = res.data.data.users.find((user: any) => user.id == userInfo.id) || null;
          const toUser = res.data.data.users.find((user: any) => user.id != userInfo.id) || null;

          navigate(routeUrls.chat);
          setTimeout(() => {
            dispatch(
              initiateSingleChat({
                fromUser: {
                  id: fromUser.id,
                  firstName: fromUser.first_name,
                  lastName: fromUser.last_name,
                  profilePic: fromUser.profile_picture,
                },
                toUser: {
                  id: toUser.id,
                  firstName: toUser.first_name,
                  lastName: toUser.last_name,
                  profilePic: toUser.profile_picture,
                },
                channelId: res.data.data.channel_id,
              }),
            );
          }, 1000);
        }
      });
    }
  };

  return (
    <Box className={styles.feedContainer} p={1}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item onClick={redirectToViewProfile} sx={{ cursor: 'pointer' }}>
          <UserAvatar name={author.name} profilePic={author.profilePic} createdAt={createdAt} />
        </Grid>
        <Grid item>{headerMenu}</Grid>
      </Grid>

      <Box>
        <ImageCarousel images={postMedia} activeImageIndex={activeImageIndex} isPhotoBlur={isPhotoBlur} />
        <Grid container alignItems="center" mt={1}>
          <Grid xs={4} textAlign="left">
            <IconButton
              sx={{
                padding: 0,
              }}
              onClick={handleLikeToggle}
              disabled={isLikeLoading}
            >
              {isLikeLoading ? <CircularProgress size={25} /> : <img src={isReacted ? ActiveLikeIcon : LikeIcon} />}
            </IconButton>
            {role === userRoles.healthCare && (
              <IconButton
                sx={{
                  padding: 0,
                }}
                onClick={handleSaveFeed}
                disabled={isSaveFeedLoading}
              >
                {isSaveFeedLoading ? <CircularProgress size={25} /> : <img src={isSaved ? ActiveFavIcon : FavIcon} />}
              </IconButton>
            )}

            {role === userRoles.healthCare && (
              <IconButton
                sx={{
                  padding: 0,
                }}
                onClick={navigateToDetailScreen}
              >
                <img src={ThinkingIcon} />
              </IconButton>
            )}
          </Grid>
          <Grid xs={4} textAlign="center">
            <CarouselIndicator
              imagesCount={postMedia.length}
              activeIndex={activeImageIndex}
              onIndexChange={setActiveImageIndex}
            />
          </Grid>

          {isChatVisible && role === userRoles.healthCare && (
            <Grid xs={4} textAlign="right">
              <Button
                onClick={handleStartChat}
                disabled={isCreateChannelLoading}
                className={isCreateChannelLoading ? 'buttonDisabled' : ''}
                type="button"
                variant="contained"
                color="success"
                size="small"
              >
                {isCreateChannelLoading ? <CircularProgress size={25} /> : 'Chat'}
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>

      <Box>
        <Typography variant="subtitle2" mt={1}>
          {likeCount} Likes
        </Typography>

        <Typography variant="h5" mt={1}>
          {title}
        </Typography>

        {CollapseView()}

        {/* {layoutToggle ? (
          <ExpandView postId={id} description={description} tags={tags} />
        ) : (
          CollapseView()
        )} */}
      </Box>
    </Box>
  );
};

export default Feed
